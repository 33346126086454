export enum LegislationType {
  FEDERAL = 'FEDERAL',
  STATE = 'STATE',
  MUNICIPAL = 'MUNICIPAL'
}

export const legislationTypeLabel = new Map<LegislationType, string>([
  [LegislationType.FEDERAL, 'Federal'],
  [LegislationType.STATE, 'Estadual'],
  [LegislationType.MUNICIPAL, 'Municipal']
]);
