import { LegislationType } from '@enums/legislation-type.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Enviroments
import { environment } from '@environments/environment';

// Models
import { Legislation } from '@models/legislation.model';

// Interfaces
import { ResponsePageableDTO } from '@interfaces/dto/response/response-pageable-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class LegislationService {

  private baseURL = `${environment.baseUrlApi}/${environment.versionApi}`;

  constructor(private http: HttpClient) { }

  add(legislation: Legislation){
    return this.http.post<Legislation>(`${this.baseURL}/moderator/legislation`, legislation);
  }

  delete(id: number){
    return this.http.delete(`${this.baseURL}/admin/legislation/${id}`);
  }

  findAll(){
    return this.http.get<Array<Legislation>>(`${this.baseURL}/covered/legislations`);
  }

  findAllPageable(page: number, size: number, areaId: number, type: LegislationType, key: string){
    if (type === null) {
      return this.http.get<ResponsePageableDTO<Legislation>>(`${this.baseURL}/covered/legislations/pageable?page=${page}&size=${size}&areaId=${areaId}&key=${key}`);
    }
    else {
      return this.http.get<ResponsePageableDTO<Legislation>>(`${this.baseURL}/covered/legislations/pageable?page=${page}&size=${size}&areaId=${areaId}&type=${type}&key=${key}`);
    }

  }
}
