import { Legislation } from "./legislation.model";

export class Area {
  id: number;
  name: string;
  legislations: Array<Legislation>;

  constructor(id?: number){
    this.id = id ? id : null;
    this.name = null;
    this.legislations = new Array();
  }

}
