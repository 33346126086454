import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Enviroments
import { environment } from '@environments/environment';

// Models
import { Project } from '@models/project.model';
import { Archive } from '@models/archive.model';
import { User } from '@models/user.model';

// Interfaces
import { ResponsePageableDTO } from '@interfaces/dto/response/response-pageable-dto.interface';

// Enums
import { ArchiveStatus } from '@enums/archive-status.enum';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private baseURL = `${environment.baseUrlApi}/${environment.versionApi}`;

  constructor(private http: HttpClient) { }

  add(project: Project){
    const associationId: number = project.association.id;
    return this.http.post<Project>(`${this.baseURL}/covered/project?associationId=${associationId}`, project);
  }

  uploadFile(formData: FormData, projectId: number){
    return this.http.post(`${this.baseURL}/covered/project/${projectId}/upload`, formData, {reportProgress: true, observe: 'events'});
  }

  changeFile(formData: FormData, project: Project, archive: Archive){
    return this.http.put(`${this.baseURL}/covered/project/${project.id}/files/${archive.id}/change`, formData, {reportProgress: true, observe: 'events'});
  }

  findById(id: number){
    return this.http.get<Project>(`${this.baseURL}/covered/project/${id}`);
  }

  findAllByStatusIn(status: Array<ArchiveStatus>, user?: User){
    if (user !== undefined){
      return this.http.get<Array<Project>>(`${this.baseURL}/covered/projects/status?status=${status}&userId=${user.id}`);
    }
    return this.http.get<Array<Project>>(`${this.baseURL}/covered/projects/status?status=${status}`);
  }

  findAllPageable(areaId: number, key: string, page: number, size: number){
    return this.http.get<ResponsePageableDTO<Project>>(`${this.baseURL}/covered/projects/pageable?page=${page}&size=${size}&sort=id,DESC&areaId=${areaId}&key=${key}`);
  }

  findAllByUserPageable(userId: number, areaId: number, key: string, page: number, size: number){
    return this.http.get<ResponsePageableDTO<Project>>(`${this.baseURL}/covered/projects/user/${userId}/pageable?page=${page}&size=${size}&sort=id,DESC&areaId=${areaId}&key=${key}`);
  }

  delete(id: number){
    return this.http.delete<void>(`${this.baseURL}/admin/project/${id}`);
  }

  count(){
    return this.http.get<number>(`${this.baseURL}/covered/project/count`);
  }

  countByStatus(status: Array<ArchiveStatus>){
    return this.http.get<number>(`${this.baseURL}/covered/project/count/status?status=${status}`);
  }

  countByUser(user: User){
    return this.http.get<number>(`${this.baseURL}/covered/project/count?userId=${user.id}`);
  }

  countByUserAndStatus(status: Array<ArchiveStatus>, user: User){
    return this.http.get<number>(`${this.baseURL}/covered/project/count/status?status=${status}&userId=${user.id}`);
  }
}
