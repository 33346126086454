import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Enviroments
import { environment } from '@environments/environment';

// Models
import { Area } from '@models/area.model';

// Interfaces
import { ResponsePageableDTO } from '@interfaces/dto/response/response-pageable-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class AreasService {

  private baseURL = `${environment.baseUrlApi}/${environment.versionApi}`;

  constructor(private http: HttpClient) {
  }

  add(area: Area): Observable<Area>{
    return this.http.post<Area>(`${this.baseURL}/moderator/area`, area);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${this.baseURL}/admin/area/${id}`);
  }

  findAll(){
    return this.http.get<Array<Area>>(`${this.baseURL}/covered/areas`);
  }

  findAllPageable(page: number, size: number, key: string): Observable<ResponsePageableDTO<Area>>{
    return this.http.get<ResponsePageableDTO<Area>>(`${this.baseURL}/covered/areas/pageable?page=${page}&size=${size}&key=${key}`);
  }

}
