// Models
import { Area } from '@models/area.model';

// Enums
import { LegislationType } from '@enums/legislation-type.enum';

export class Legislation{
  id: number;
  name: string;
  initials: string;
  state: string;
  city: string;
  type: LegislationType;
  area: Area;

  constructor(id?: number){
    this.id = id ? id : null;
    this.name = null;
    this.initials = null;
    this.state = null;
    this.city = null;
    this.type = null;
    this.area = new Area();
  }

}
